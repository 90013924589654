
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }

a {
	color: white
}
a:visited { 
	color: white
}
a:hover { 
	color: white
}

#centering {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

#clock {
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
}

.padded-left {
	padding-left: 1em;
}
.padded-right {
	padding-right: 1em;
}

@font-face {
	font-family: 'minecraftfont';
	src: url('./fonts/minecraftfont.eot');
	src: local('minecraftfont'), url('./fonts/minecraftfont.woff') format('woff'), url('./fonts/minecraftfont.ttf') format('truetype');
}
/* use this class to attach this font to any element i.e. <p class="fontsforweb_fontid_1412">Text with this font applied</p> */
.fontsforweb_fontid_1412 {
	font-family: 'minecraftfont' !important;

}

@font-face {
	font-family: 'print21';
	src: url('./fonts/PrintChar21.eot');
	src: local('print21'), url('./fonts/PrintChar21.woff') format('woff'), url('./fonts/PrintChar21.ttf') format('truetype');
}

